import React from 'react';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { AuthStart } from './components/Auth/AuthStart';
import { AuthEnd } from './components/Auth/AuthEnd';
import { LogoutStart } from './components/Auth/LogoutStart';
import { LogoutEnd } from './components/Auth/LogoutEnd';
import RedirectTeams from './components/RedirectTeams';
import { TeamsAuth } from './components/TeamsAuth';
import { TeamsAuthStart } from './components/TeamsAuthStart';
import { TeamsAuthEnd } from './components/TeamsAuthEnd';
import store from './store';
import { getLoginPath } from './tools/authTools';
import { PageNotFound } from './components/404';
import { instanceConfig } from './instance';
import Home from './routes/Home';
import RegisterContainerV2 from './routes/RegisterContainerV2';
import SettingsForm from './routes/SettingsForm';
import CaseCreationContainer from './routes/CaseCreationContainer';
import ValidationForm from './routes/ValidationForm';
import NotificationsContainer from './routes/NotificationsContainer';
import EvaluationConfirmationContainer from './routes/EvaluationConfirmationContainer';
import LegalEntitiesContainer from './routes/LegalEntitiesContainer';
import NewLegalEntity from './routes/NewLegalEntity';
import RfpInitialProporsal from './routes/RfpInitialProporsal';
import RfpSimpleComponent from './routes/RfpSimpleComponent';
import RfpFinancialsAndTimeline from './routes/RfpFinancialsAndTimeline';
import RfpRecipients from './routes/RfpRecipients';
import RfpSummary from './routes/RfpSummary';
import RfpExtras from './routes/RfpExtras';
import RfpSubmit from './routes/RfpSubmit';
import RfpWithdraw from './routes/RfpWithdraw';
import ProposalIntroduction from './routes/ProposalIntroduction';
import ProposalScopeTimeline from './routes/ProposalScopeTimeline';
import ProposalFinancials from './routes/ProposalFinancials';
import ProposalTeam from './routes/ProposalTeam';
import ProposalSummary from './routes/ProposalSummary';
import RFPRecipientSummary from './routes/RFPRecipientSummary';
import ProposalSumbission from './routes/ProposalSumbission';
import ManageMyProposals from './routes/ManageMyProposals';
import ProposalExtras from './routes/ProposalExtras';
import WithdrawProposal from './routes/WithdrawProposal';
import CLEGraphExport from './routes/CLEGraphExport';
import PLEPreviewExport from './routes/PLEPreviewExport';
import TeamsConfigCluster from './routes/TeamsConfigCluster';
import ClusterContent from './routes/ClusterContent';
import { OrganizationMainContainer } from './components/Organization/OrganizationMainContainer';
import BranchCompare from './components/Soge/BranchCompare/BranchCompare';
import { Layout } from './components/Layout/Layout';

const ProtectedRoute = (data: any) => {
    const isLoggedIn = store.getState().context.isLoggedIn;
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    if (isLoggedIn || params.get('shadowAuth') === '1') {
        return <Outlet />;
    } else {
        params.get('shadowAuth') !== '1' && window.location.replace(getLoginPath());
        return <Navigate to={data.redirectPath} replace />;
    }
};

export const routerNew = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Outlet />}>
            <Route element={<ProtectedRoute redirectPath={`/login?nextRequestUrl=${encodeURIComponent(`${window.location.pathname}${window.location.search}${window.location.hash}`)}`} />}>
                <Route index element={<Home isHomeRoute />} />
                <Route path="create-contract/:organization?" element={<Home />} key="home" />
                <Route path="create-gift/:organization?" element={<Home />} key="home" />
                <Route path="create-legal-support/:organization?" element={<Home />} key="home" />
                <Route path="create-lobbying/:organization?" element={<Home />} key="home" />
                <Route path="create-poa/:organization?" element={<Home />} key="home" />
                <Route path="create-eapproval/:organization?" element={<Home />} key="home" />
                <Route path="create-invitation/:organization?" element={<Home />} key="home" />
                <Route path="create-coi/:organization?" element={<Home />} key="home" />
                <Route path="create-sponsoring/:organization?" element={<Home />} key="home" />
                <Route path="create-trade-association/:organization?" element={<Home />} key="home" />
                <Route path="invitations" element={<Home />} />
                <Route path="manage-requests" element={<Home />} />
                <Route path="manage-proposals" element={<Home />} />
                <Route path="/" element={<Layout />}>
                    <Route path="news" lazy={() => import('./components/Routes/News/NewsList')} />
                    <Route path="news/edit/:id" lazy={() => import('./components/Routes/News/AddNewOrEditNews')} />
                    <Route path="news/new" lazy={() => import('./components/Routes/News/AddNewOrEditNews')} />
                    <Route path="news/:id" lazy={() => import('./components/Routes/News/ViewNews')} />
                    <Route path="profile/:id/:tabId?" lazy={() => import('./components/Profiles/User')} />
                    <Route path="admin-console" lazy={() => import('./components/Routes/AdminConsole/AdminConsoleContainerV2')} />
                    <Route path="directory" lazy={() => import('./components/SearchContainers/GlobalDirectory')} />
                    <Route path="resume-your-work" lazy={() => import('./components/Home/ResumeYourWork')} />
                    <Route path="billing" lazy={() => import('./components/Routes/Billing/Billing')} />
                    <Route path="/null" lazy={() => import('./components/Home/NullPage')} />
                    <Route path="registration-final-step" lazy={() => import('./components/Routes/Registration/Registration')} />
                    <Route path="groups/:groupId" lazy={() => import('./components/Routes/AdminConsole/V2/Groups/SingleGroup')} />
                </Route>
                <Route path="case/create" element={<CaseCreationContainer />} />
                <Route path="notifications" element={<NotificationsContainer />} />
                <Route path="settings" element={<SettingsForm />} />
                <Route path="rfp" element={<RfpInitialProporsal />} />
                <Route path="rfp/description/:rfpId?" element={<RfpInitialProporsal />} />
                <Route path="rfp/new" element={<RfpSimpleComponent />} />
                <Route path="rfp/financials-timeline/:rfpId?" element={<RfpFinancialsAndTimeline />} />
                <Route path="rfp/recipients/:rfpId?" element={<RfpRecipients />} />
                <Route path="rfp/extras/:rfpId?" element={<RfpExtras />} />
                <Route path="rfp/summary/:rfpId?" element={<RfpSummary />} />
                <Route path="rfp/submit/:rfpId?" element={<RfpSubmit />} />
                <Route path="rfp/withdraw/:rfpId?" element={<RfpWithdraw />} />
                <Route path="rfp/complete/:rfpId?" element={<RFPRecipientSummary />} />
                <Route path="proposal/introduction/:proposalId?" element={<ProposalIntroduction />} />
                <Route path="proposal/scope-timeline/:proposalId?" element={<ProposalScopeTimeline />} />
                <Route path="proposal/financials/:proposalId?" element={<ProposalFinancials />} />
                <Route path="proposal/team/:proposalId?" element={<ProposalTeam />} />
                <Route path="proposal/extras/:proposalId?" element={<ProposalExtras />} />
                <Route path="proposal/summary/:proposalId?" element={<ProposalSummary />} />
                <Route path="proposal/rfpSummary/:rfpId?" element={<RFPRecipientSummary />} />
                <Route path="proposal/submit/:proposalId?" element={<ProposalSumbission />} />
                <Route path="proposal/withdraw/:proposalId?" element={<WithdrawProposal />} />
                <Route path="rfp/:rfpId?/proposals" element={<ManageMyProposals />} />
                <Route path="companies" element={<LegalEntitiesContainer />} />
                <Route path="company/new" element={<NewLegalEntity />} />
                <Route path="company/:entityId" element={<LegalEntitiesContainer />} />
                <Route path="company/edit/:entityId" element={<NewLegalEntity />} />
                <Route path="orgs/:organization" element={<OrganizationMainContainer />}>
                    <Route path="settings" lazy={() => import('./components/Organization/Settings')} />
                    <Route path="clusters" lazy={() => import('./components/Organization/ClustersContainer')} />
                    <Route path="dashboard" lazy={() => import('./components/Organization')} />
                    <Route path="questions" lazy={() => import('./components/AssetsManagement/Q&A/QAndADatagrid')} />
                    <Route path="allFAQ" lazy={() => import('./components/AssetsManagement/FAQ/FAQDatagrid')} />
                    <Route path="composite-side-letter" lazy={() => import('./components/AssetsManagement/SideLetter/MainSLDatatable')} />
                    <Route path="tracker" lazy={() => import('./components/AssetsManagement/Tracker/MainTrackerDatagrid')} />
                    <Route path="landlord-owner" lazy={() => import('./components/Organization/Landlord/OwnerDataTable/OwnerDataTable')} />
                    <Route path="clausier" lazy={() => import('./components/AssetsManagement/Clausier/ClausierDatagrid')} />
                    <Route path="clausier-poa" lazy={() => import('./components/AssetsManagement/Clausier/ClausierPOADatagrid')} />
                    <Route path="allRights" lazy={() => import('./components/AssetsManagement/Rights/AllRightsDatagrid')} />
                    <Route path="quotation" lazy={() => import('./components/Organization/Routes/Quotation/QuotationContainer')} />
                    <Route path="campaigns" lazy={() => import('./components/Organization/Campaign/CampaignsList')} />
                    <Route path="campaign/create" lazy={() => import('./components/Organization/Campaign/CampaignCreate')} />
                    <Route path="campaign/edit/:campaignId" lazy={() => import('./components/Organization/Campaign/CampaignCreate')} />
                    <Route path="campaign/:campaignId" lazy={() => import('./components/Organization/Campaign/SingleCampaign')} />
                    <Route path="quotation/:id" lazy={() => import('./components/Equitis/QuotationDetails/QuotationDetailsContainer')} />
                    <Route path="rfp/myrequests" lazy={() => import('./components/Organization/Rfp/MyRequests')} />
                    <Route path="rfp/fasttrack/:rfpId" lazy={() => import('./components/Organization/Rfp/FastTrack')} />
                    <Route path="rfp/fasttracksummary/:rfpId" lazy={() => import('./components/Organization/Rfp/Summary')} />
                    <Route path="rfp/description/:rfpId" lazy={() => import('./components/RFP/Description/Description')} />
                    <Route path="rfp/financials-timeline/:rfpId" lazy={() => import('./components/RFP/FinancialsAndTimeline/FinancialsAndTimeline')} />
                    <Route path="rfp/recipients/:rfpId" lazy={() => import('./components/RFP/Recipients/Recipients')} />
                    <Route path="rfp/extras/:rfpId" lazy={() => import('./components/RFP/Extras/Extras')} />
                    <Route path="rfp/summary/:rfpId" lazy={() => import('./components/RFP/Summary/Summary')} />
                    <Route path="directory" lazy={() => import('./components/Common/Directory')} />
                    <Route path="poa-holders" lazy={() => import('./components/Common/Directory')} />
                    <Route path="clusters/stake-grid/:stakeId" lazy={() => import('./components/Organization/SingleStakeDataGrid')} />
                    <Route path="clusters/:datagridKey" lazy={() => import('./components/Organization/ClustersContainer')} />
                    <Route path="approvals" lazy={() => import('./components/Organization/Routes/Approval/OrganizationApprovalDatatable')} />
                    <Route path="panel" lazy={() => import('./components/Organization/Panel/Panel')} />
                    <Route path="entities/:tabName?" lazy={() => import('./components/Organization/CLE/CLEContainer')} />
                    <Route path="panel/law-firm/new" lazy={() => import('./components/Organization/Panel/AddLawFirm')} />
                    <Route path="panel/law-firm/:lawFirmId" lazy={() => import('./components/Organization/Panel/ViewLawFirm')} />
                    <Route path="panel/law-firm/:lawFirmId/edit" lazy={() => import('./components/Organization/Panel/AddLawFirm')} />
                    <Route path="cluster/:id" lazy={() => import('./components/Organization/ClustersV2/ClusterPageWrapper/ClusterPageWrapper')} />
                    <Route path="documentation" lazy={() => import('./components/Soge/Documentation/SogeDocumentation')} />
                    <Route path="documentation/search/:versionId?" lazy={() => import('./components/Soge/Search/Search')} />
                    <Route path="documentation/manage-versions" lazy={() => import('./components/Soge/DocumentVersions/DocumentVersions')} />
                    <Route path="documentation/norms-management" lazy={() => import('./components/Soge/NormsManagement/NormsManagement')} />
                    <Route path="documentation/move-content" lazy={() => import('./components/Soge/MoveContent/MoveContent')} />
                    <Route path="documentation/versions/settings" lazy={() => import('./components/Soge/DocumentVersions/SettingsVersions')} />
                    <Route path="documentation/versions/:versionId/amendements" lazy={() => import('./components/Soge/DocumentVersions/AmendementsVersions')} />
                    <Route path="documentation/versions/:versionId/amendements/datagrid" lazy={() => import('./components/Soge/Amendments/AmendmentsDatagrid')} />
                    <Route path="documentation/versions/new-amendement/:step?" lazy={() => import('./components/Soge/Amendments/CreateAmendment')} key="amendment" />
                    <Route path="documentation/versions/new-amendement/:step?/:amendmentId?" lazy={() => import('./components/Soge/Amendments/CreateAmendment')} key="amendment" />
                    <Route path="documentation/versions/:versionId" lazy={() => import('./components/Soge/DocumentVersions/VersionOverviewContainer')} key="version-overview" />
                    <Route path="documentation/versions/:versionId/structure/:structureId/:articleId?" lazy={() => import('./components/Soge/DocumentVersions/VersionOverviewContainer')} key="version-overview" />
                    <Route path="documentation/versions/:versionId/branches" lazy={() => import('./components/Soge/Branches/SogeBranches')} />
                    <Route path="documentation/versions/:versionId/branches/datagrid" lazy={() => import('./components/Soge/Branches/BranchesDatagrid')} />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/request-approval" element={<BranchCompare requestApprovalMode />} key="branch-compare" />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/approve/:requestId" element={<BranchCompare approveMode />} key="branch-compare" />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/:branchId" element={<BranchCompare />} key="branch-compare" />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/article/:articleId" lazy={() => import('./components/Soge/ArticleDetails/BranchArticleDetails')} />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/structure-level/:structureId" lazy={() => import('./components/Soge/StructureDetails/StructureDetails')} />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/manage-structure" lazy={() => import('./components/Soge/ManageBranchStructure/ManageBranchStructure')} />
                    <Route path="documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/audit-trail" lazy={() => import('./components/Soge/BranchCompare/AuditTrail')} />
                    <Route path="documentation/versions/:versionId/approvals" lazy={() => import('./components/Soge/Branches/SogeApprovals')} />
                    <Route path="documentation/versions/:versionId/datagrid" lazy={() => import('./components/Soge/Branches/VersionDatagrid')} />
                    <Route path="documentation/versions/:versionId/new-branch" lazy={() => import('./components/Soge/Branches/NewBranch')} />
                    <Route path="documentation/collections/:versionId?" lazy={() => import('./components/Soge/Code/CodeOfConduct')} key="sg-consultation" />
                    <Route path="documentation/collections/:versionId/:commonLevelId/texts" lazy={() => import('./components/Soge/Code/BookReferenceTexts/BookReferenceTexts')} key="sg-consultation" />
                    <Route path="documentation/texts/:versionId?" lazy={() => import('./components/Soge/Code/Texts/AllTexts')} key="sg-consultation" />
                    <Route path="documentation/archives" lazy={() => import('./components/Soge/Archives/CodeArchives')} />
                    <Route path="documentation/avis" lazy={() => import('./components/Soge/Notices/SogeNotices')} />
                    <Route path="documentation/reporting" lazy={() => import('./components/Soge/Reporting/Reporting')} />
                    <Route path="documentation/:structureId/:versionId/article/:articleId" lazy={() => import('./components/Soge/LegalDocument/SogeArticle')} />
                    <Route path="documentation/:structureId" lazy={() => import('./components/Soge/LegalDocument/LegalDocument')} key="structure-level" />
                    <Route path="documentation/:structureId/:versionId" lazy={() => import('./components/Soge/LegalDocument/LegalDocument')} key="structure-level" />
                    <Route path="cluster/:id/:page" lazy={() => import('./components/Organization/ClustersV2/ClusterPageWrapper/ClusterPageWrapper')} />
                    <Route path="cluster/:id/:page/:messageId" lazy={() => import('./components/Organization/ClustersV2/ClusterPageWrapper/ClusterPageWrapper')} />
                </Route>
                <Route path="teams/config" element={<TeamsConfigCluster />} />
                <Route path="teams/orgs/:organization/cluster/:id" element={<ClusterContent isTeamsRoute />} />
                <Route path="teams/orgs/:organization/cluster/:id/:page" element={<ClusterContent isTeamsRoute />} />
                <Route path="teams/orgs/:organization/cluster/:id/:page/:messageId" element={<ClusterContent isTeamsRoute />} />
            </Route>
            <Route path="/" element={<Layout />}>
                <Route path="/terms" lazy={() => import('./components/CopyrightAndTerms/TermsAndConditionsContainer')} />
                <Route path="/copyrights" lazy={() => import('./components/CopyrightAndTerms/CopyrightsContainer')} />
                <Route path="/logout" lazy={() => import('./components/Auth/Logout')} />
            </Route>
            <Route path="/register/:accountCreationKey?" element={<RegisterContainerV2 isRegisterRoute />} />
            <Route path="/case/confirmation" element={<EvaluationConfirmationContainer />} />
            <Route path="/case/evaluate/validation" element={(instanceConfig.routes || []).indexOf('/case/evaluate/validation') === -1 ? <PageNotFound /> : <ValidationForm />} />
            <Route path="/login" element={<AuthStart />} />
            <Route path="/auth-callback" element={<AuthEnd />} />
            <Route path="/reset-password" element={<AuthStart passwordResetMode />} />
            {IS_TEAMS_INSTANCE && (
                <Route path="/redirect-teams" element={<RedirectTeams />} />
            )}
            {IS_TEAMS_INSTANCE && (
                <Route path="/teamsauth" element={<TeamsAuth />} />
            )}
            {IS_TEAMS_INSTANCE && (
                <Route path="/teams/auth-start" element={<TeamsAuthStart />} />
            )}
            {IS_TEAMS_INSTANCE && (
                <Route path="/teams/auth-end" element={<TeamsAuthEnd />} />
            )}
            {IS_TEAMS_INSTANCE && (
                <Route path="/teams/logout-start" element={<LogoutStart />} />
            )}
            {IS_TEAMS_INSTANCE && (
                <Route path="/teams/logout-end" element={<LogoutEnd />} />
            )}
            <Route path="/orgs/:organizationId/entities/export-graph" element={<CLEGraphExport />} />
            <Route path="/company/:entityId/export-entity" element={<PLEPreviewExport />} />
            <Route path="*" element={<PageNotFound />} />
        </Route>
    )
);